

import React, { Component } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { trackClickWithFunctions } from 'src/analytics';
import AppButton from 'src/components/common/app-button';
import AppIcon from 'src/components/common/app-icon';
import AppMoreIconButton from 'src/components/common/app-more-icon-button';
import PromiseButton from 'src/components/common/promise-button';
import chaining from 'src/utils/chaining';
import stopPropagation from 'src/utils/stop-propagation';

const clsPrefix = 'app-actions-menu';

// The point of this is to let an item handle it's own click action.
// This uses onClick_ instead of onClick because Menu suppresses it's
// immediate children onClick method.
const ButtonItem = ({ className, onClick_, children, disabled, href }) => {
  const clsName = classNames('ant-dropdown-menu-item', className, {
    'ant-dropdown-menu-item-disabled': disabled,
  });
  if (href) {
    return (
      <a className={clsName} href={href} disabled={disabled}>
        {children}
      </a>
    );
  }
  return (
    <div
      className={clsName}
      role="button"
      onClick={disabled ? stopPropagation : onClick_}
    >
      {children}
    </div>
  );
};

ButtonItem.propTypes = {
  className: PropTypes.string,
  onClick_: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  href: PropTypes.string,
};

@inject('ui')
@observer
export default class ActionsMenu extends Component {
  static SubMenu = Menu.SubMenu;
  static ButtonItem = ButtonItem;
  static Item = Menu.Item;
  static ItemGroup = Menu.ItemGroup;

  static propTypes = {
    ui: PropTypes.object.isRequired,
    className: PropTypes.string,
    onMenuClick: PropTypes.func,
    menu: PropTypes.any,
    size: PropTypes.string,
    dropdownButtonProps: PropTypes.object,
    dropdownProps: PropTypes.object,
    dropdownLabel: PropTypes.any,
    leading: PropTypes.any,
    overlay: PropTypes.any,
    overlayProps: PropTypes.object,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    button: PropTypes.any,
    compactOnMobile: PropTypes.bool,
    onVisibleChange: PropTypes.func,
    circular: PropTypes.bool,
    visible: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
    };
  }

  getMenuItem = (key) => {
    if (key === undefined || !this.props.menu) {
      return null;
    }

    return this.props.menu[key];
  };

  handleClick =
    (...handlers) =>
    async (e) => {
      if (!e) {
        return;
      }

      if (e.domEvent) {
        e.domEvent.stopPropagation();
      }

      if (e.stopPropagation) {
        e.stopPropagation();
      }

      this.setState({
        loading: true,
        visible: false,
      });

      try {
        for (let i = 0; i < handlers.length; i++) {
          if (handlers[i]) {
            // eslint-disable-next-line no-await-in-loop
            await handlers[i](e ? e.key : undefined);
          }
        }
      } finally {
        this.setState({
          loading: false,
          visible: false,
        });
      }
    };

  _handleClick = (key) => {
    if (this.props.onMenuClick) {
      return this.props.onMenuClick(key);
    }

    if (!this.props.menu) {
      return null;
    }

    const menuItem = this.getMenuItem(key);

    if (!menuItem.nested) {
      this.onVisibleChange(false);
    }

    if (menuItem && typeof menuItem === 'function') {
      return this.props.menu[key](key);
    }
    return null;
  };

  getNormalizedMenuItems() {
    /* Transforms short hand menu declaration to an array of menu item objects  */
    return map(this.props.menu, (v, k) => {
      const item = {
        key: k,
        label: v.label || k,
        disabled: v.disabled || false,
        textColor: v.textColor || null,
        handler: v.handler,
        nested: v.nested,
        wrapper: v.wrapper || null,
      };

      if (typeof v === 'function') {
        item.handler = v;
      }
      return item;
    });
  }

  renderChildren = () => {
    const { children } = this.props;
    if (isFunction(children)) {
      return children(this.handleClick);
    }
    return children;
  };

  renderMenuItem = (item) => {
    // Track click events for eSign entry flow
    // https://compass-tech.atlassian.net/browse/TMES-122
    if (item.label === 'eSign') {
      const clickWithTracking = trackClickWithFunctions(
        'Clicked Esign',
        'Document Checklist Popover',
        item.handler
      );
      item.handler = clickWithTracking;
    }

    const menuItemInner = (
      <span
        style={
          item.textColor
            ? {
                color: item.textColor,
              }
            : null
        }
      >
        {item.label}
      </span>
    );

    return (
      <Menu.Item key={item.key} disabled={item.disabled}>
        {item.wrapper ? item.wrapper(menuItemInner) : menuItemInner}
      </Menu.Item>
    );
  };

  overlayFromObject = () => {
    return map(this.getNormalizedMenuItems(), (item) => {
      return item.nested ? (
        <Menu.SubMenu title={item.label} key={item.key}>
          {item.handler({
            handleClick: this.handleClick,
          })}
        </Menu.SubMenu>
      ) : (
        this.renderMenuItem(item)
      );
    });
  };

  renderLeading() {
    const { leading: rawLeading, menu } = this.props;
    const leading = isArray(rawLeading) ? rawLeading : [rawLeading];
    return leading
      .filter((x) => x)
      .map((leadingEl) =>
        leadingEl.action
          ? menu[leadingEl.action] && (
              <PromiseButton
                {...leadingEl}
                key={leadingEl.action}
                onClick={menu[leadingEl.action]}
              >
                {leadingEl.action}
              </PromiseButton>
            )
          : leadingEl
      );
  }

  get visibilityControlled() {
    return this.props.visible !== undefined;
  }

  get visible() {
    if (this.visibilityControlled) {
      return !!this.props.visible;
    }
    return !!this.state.visible;
  }

  onVisibleChange = async (visible) => {
    const { onVisibleChange } = this.props;
    if (onVisibleChange) {
      await onVisibleChange(visible);
    }
    this.setState({
      visible,
    });
  };

  render() {
    const {
      ui,
      className,
      size,
      dropdownButtonProps,
      dropdownProps,
      dropdownLabel,
      overlay,
      overlayProps,
      onClick,
      disabled,
      button,
      compactOnMobile,
      circular,
    } = this.props;

    const loading = this.state.loading || this.props.loading;
    const compactOnMobileAndIsMobile = compactOnMobile && ui.isMobileSize;

    const defaultBtn = !dropdownLabel ? (
      <AppMoreIconButton
        className={classNames(`${clsPrefix}__dd-btna`, {
          [`${clsPrefix}__dd-btna--active`]: this.visible,
        })}
        disabled={disabled}
        iconSize={14}
        {...(dropdownButtonProps || {})}
        loading={!!loading}
        buttonSize={size}
      />
    ) : (
      <AppButton iconSize={14} {...(dropdownButtonProps || {})} size={size}>
        <span>{dropdownLabel}</span>
        <AppIcon
          type="antd"
          name={loading ? 'loading' : 'down'}
          style={{
            marginLeft: '4px',
          }}
        />
      </AppButton>
    );

    return (
      <Button.Group
        size={compactOnMobileAndIsMobile ? 'small' : size}
        className={classNames(clsPrefix, `${clsPrefix}--${size}`, className, {
          [`${clsPrefix}--circular`]: circular,
        })}
      >
        {!compactOnMobileAndIsMobile && this.renderLeading()}
        <Dropdown
          trigger={['click']}
          placement="bottomRight"
          overlay={
            overlay ? (
              React.cloneElement(overlay, {
                onClick: chaining(
                  this.handleClick(this._handleClick),
                  overlay.props.onClick
                ),
              })
            ) : (
              <Menu
                {...(overlayProps || {})}
                onClick={chaining(this.handleClick(this._handleClick), onClick)}
                selectable={false}
                className={`${clsPrefix}__menu`}
              >
                {this.props.children
                  ? this.renderChildren()
                  : this.overlayFromObject()}
              </Menu>
            )
          }
          visible={this.visible}
          onVisibleChange={(visible) => this.onVisibleChange(visible)}
          onClick={stopPropagation}
          {...dropdownProps}
        >
          {button || defaultBtn}
        </Dropdown>
      </Button.Group>
    );
  }
}
